var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.pindaanList == [] ? _c('div', {
    staticClass: "p-5 text-center"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("no-records-found")))])]) : _vm._e(), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table mb-0 table-center table-sm"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    staticStyle: {
      "width": "500px"
    }
  }, [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('th', [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("action")))])])]), _c('tbody', [_vm._l(_vm.pindaanList, function (pindaan, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index))]), _c('td', [_vm._v(" " + _vm._s(pindaan.amendmentable ? pindaan.amendmentable.user.name : "-") + " ")]), _c('td', [_vm._v(" " + _vm._s(pindaan.amendmentable ? pindaan.amendmentable.user.ic_number : "-") + " ")]), _c('td', [_c('button', {
      staticClass: "btn btn-primary btn-sm",
      on: {
        "click": function ($event) {
          return _vm.pengesahanPindaan(pindaan.amendmentable_id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("verification")) + " ")])])]);
  }), _vm.pindaanList.length == 0 ? _c('tr', [_c('td', {
    staticClass: "p-5 text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("no-records-found")))])])]) : _vm._e()], 2)])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-8"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.pagination.totalRows,
      "per-page": _vm.pagination.perPage,
      "aria-controls": "my-table"
    },
    on: {
      "change": _vm.pageClicks
    },
    model: {
      value: _vm.pagination.currentPage,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "currentPage", $$v);
      },
      expression: "pagination.currentPage"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form custom-form"
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.pagination.perPage,
      expression: "pagination.perPage"
    }],
    staticClass: "form-control custom-select",
    attrs: {
      "id": "Sortbylist-job"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.pagination, "perPage", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.changePerPage();
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v("10 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "25"
    }
  }, [_vm._v("25 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v("50 " + _vm._s(_vm.$t("per-page")))])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }